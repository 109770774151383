/**
 * @file standalone/ugc.scss
 *
 * \brief Library file - shared styles for UGC headers
 *
 */

@import '../theme-bootstrap';

.ugc-headings {
  &__heading {
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: $color-off-black;
    pointer-events: none;
    padding-bottom: 5px;
  }
  &__subheading {
    display: flex;
    justify-content: center;
    line-height: 1.5;
    color: $color-medium-gray3;
    font-size: 14px;
    padding-bottom: 12px;
    text-align: center;
  }
}
